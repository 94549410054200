html {
  scroll-behavior: smooth;
}

/* Define Gin-Regular */
@font-face {
  font-family: "Gin-Regular";
  src: url("/src/fonts/Gin-Regular.otf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

/* Define Grandstander */
@font-face {
  font-family: "Grandstander";
  src: url("/src/fonts/Grandstander-VariableFont_wght.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

.MARV-m {
  background-color: transparent;
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
}

.MARV-m .div {
  background-image: url(../../../static/img/MBG.png);
  background-size: cover;
  border-radius: 40px;
  width: 393px;
  height: 4900px;
  min-height: 100vh;
  margin: 5px;
  padding: 5px;
  position: absolute;
  overflow: hidden;
}

.MARV-m .NAV {
  width: 338px;
  height: 58px;
  position: absolute;
  top: 28px;
  left: 25px;
}

.MARV-m .LOGO {
  object-fit: cover;
  cursor: pointer;
  height: 34px;
  position: absolute;
  top: 14px;
  left: 15px;
}

.MARV-m .story-b {
  width: 71px;
  height: 33px;
  position: absolute;
  top: 18px;
  left: 155px;
}

.MARV-m .group {
  height: 33px;
  width: 73px;
}

.MARV-m .overlap-group {
  border: 0.5px solid;
  border-color: #ffffff;
  border-radius: 10px;
  height: 33px;
  position: relative;
  width: 71px;
}

.MARV-m .text-wrapper {
  color: #fff;
  letter-spacing: 0;
  text-align: center;
  width: 71px;
  height: 32px;
  font-family: Gin-Regular, Helvetica;
  font-size: 19px;
  font-weight: 400;
  line-height: normal;
  position: absolute;
  top: 4px;
  left: 0;
}

.MARV-m .medium-b {
  background-color: #72b26e;
  border-radius: 10px;
  height: 33px;
  left: 745px;
  position: absolute;
  top: 18px;
  width: 36px;
}

.MARV-m .medium {
  height: 15px;
  left: 7px;
  position: absolute;
  top: 10px;
  width: 18px;
}

.MARV-m .tellagram-b {
  background-color: #72b26e;
  border-radius: 10px;
  width: 36px;
  height: 33px;
  position: absolute;
  top: 18px;
  left: 161px;
}

.MARV-m .medium-2 {
  width: 22px;
  height: 15px;
  position: absolute;
  top: 9px;
  left: 4px;
}

.MARV-m .telegram {
  height: 15px;
  left: 7px;
  position: absolute;
  top: 10px;
  width: 18px;
}

.MARV-m .twitter-b {
  background-color: #72b26e;
  border-radius: 10px;
  width: 36px;
  height: 33px;
  position: absolute;
  top: 18px;
  left: 199px;
}

.MARV-m .twitter {
  height: 17px;
  left: 9px;
  position: absolute;
  top: 8px;
  width: 17px;
}

.MARV-m .buy-b {
  width: 158px;
  height: 47px;
  position: absolute;
  top: 18px;
  left: 237px;
}

.MARV-m .medium-b-3 {
  background-color: #72b26e;
  border-radius: 10px;
  width: 36px;
  height: 33px;
  position: absolute;
  top: 206px;
  left: 141px;
}

.MARV-m .overlap-group-wrapper {
  height: 33px;
  width: 113px;
}

.MARV-m .div-wrapper {
  cursor: pointer;
  background: linear-gradient(#995ee5 0%, #55347f 100%);
  border-radius: 10px;
  width: 96px;
  height: 33px;
  position: relative;
}

.MARV-m .text-wrapper-2 {
  color: #fff;
  letter-spacing: 0;
  text-align: center;
  width: 121px;
  height: 32px;
  font-family: Gin-Regular, Helvetica;
  font-size: 18px;
  font-weight: 400;
  line-height: normal;
  position: absolute;
  top: 4px;
  left: -13px;
}

.MARV-m .landing-text {
  text-align: center;
  width: 100%;
  position: absolute;
  top: 134px;
  left: 0;
}

.MARV-m .text-wrapper-welcome {
  -webkit-text-stroke: 1px #056825;
  color: #fff;
  letter-spacing: 0;
  text-shadow: 0 4px 15px #00000040;
  margin-bottom: 10px;
  font-family: Gin-Regular, Helvetica;
  font-size: 48px;
  font-weight: 400;
  display: block;
}

.MARV-m .text-wrapper-marv {
  -webkit-text-stroke: 1px #056825;
  color: #fff;
  letter-spacing: 0;
  text-shadow: 0 4px 15px #00000040;
  font-family: Gin-Regular, Helvetica;
  font-size: 50px;
  font-weight: 400;
  display: block;
}

.MARV-m .derived-from-love {
  color: #fff;
  letter-spacing: 0;
  text-align: center;
  width: 393px;
  font-family: Grandstander, Helvetica;
  font-size: 20px;
  font-weight: 400;
  line-height: normal;
  position: absolute;
  top: 70px;
  left: 0;
}

.MARV-m .text-wrapper-4 {
  -webkit-text-stroke: 1px #378b32;
  color: #ffffff;
  font-family: "Gin-Regular", Helvetica;
  font-size: 44px;
  font-weight: 400;
  height: 88px;
  left: -1px;
  letter-spacing: -0.88px;
  line-height: normal;
  position: absolute;
  text-align: center;
  top: 335px;
  width: 170px;
}

.MARV-m .ticket-tape {
  white-space: nowrap;
  /* width: 334px; */
  height: 170px;
  display: flex;
  position: absolute;
  top: 570px;
  overflow: hidden;
}

.MARV-m .p {
  color: #55347f;
  font-family: "Gin-Regular", Helvetica;
  font-size: 32px;
  line-height: 85px; /* Ensures vertical alignment within each line */
  animation: scrollText 20s linear infinite;
}

/* Keyframes for the scrolling text animation */
@keyframes scrollText {
  0% {
    transform: translateX(0%); /* Start with text fully visible */
  }
  100% {
    transform: translateX(-50%); /* Move to left half the total width */
  }
}

.MARV-m .token {
  width: 338px;
  height: 140px;
  position: absolute;
  top: 681px;
  left: 0;
}

.MARV-m .text-wrapper-5 {
  -webkit-text-stroke: 1px #056825;
  color: #fff;
  letter-spacing: 0;
  text-align: center;
  width: 759px;
  height: 111px;
  font-family: Gin-Regular, Helvetica;
  font-size: 39px;
  font-weight: 400;
  line-height: normal;
  position: absolute;
  top: 16px;
  left: -187px;
}

.MARV-m .text-wrapper-6 {
  color: #fff;
  letter-spacing: 0;
  text-align: center;
  width: 338px;
  font-family: Grandstander, Helvetica;
  font-size: 17px;
  font-weight: 400;
  line-height: normal;
  position: absolute;
  top: 73px;
  left: 26px;
}

.MARV-m .boxes {
  width: 338px;
  height: 409px;
  position: absolute;
  top: 146px;
  left: 7px;
}

.MARV-m .overlap {
  width: 365px;
  height: 409px;
  position: absolute;
  top: 426px;
  left: 32px;
}

.MARV-m .rectangle {
  background-color: #d9d9d9;
  border-radius: 40px;
  width: 320px;
  height: 409px;
  position: absolute;
  top: 0;
  left: 2px;
}

.MARV-m .img {
  width: 291px;
  height: 284px;
  position: absolute;
  top: 27px;
  left: 13px;
}

.MARV-m .text-wrapper-7 {
  color: #72b26e;
  letter-spacing: 0;
  text-align: center;
  width: 364px;
  height: 98px;
  font-family: Gin-Regular, Helvetica;
  font-size: 37px;
  font-weight: 400;
  line-height: normal;
  position: absolute;
  top: 328px;
  left: -20px;
}

.MARV-m .overlap-group-2 {
  background-color: #d9d9d9;
  border-radius: 40px;
  width: 320px;
  height: 409px;
  position: absolute;
  top: 5px;
  left: 31px;
}

.MARV-m .rectangle-2 {
  width: 291px;
  height: 284px;
  position: absolute;
  top: 27px;
  left: 14px;
}

.MARV-m .overlap-2 {
  background-color: #d9d9d9;
  border-radius: 40px;
  width: 320px;
  height: 409px;
  position: absolute;
  top: 852px;
  left: 36px;
}

.MARV-m .CA {
  color: #fff;
  letter-spacing: 0;
  text-align: center;
  width: 323px;
  font-family: Grandstander, Helvetica;
  font-size: 26px;
  font-weight: 400;
  line-height: normal;
  position: absolute;
  top: 1435px;
  left: 36px;
}

.MARV-m .span {
  color: #fff;
  letter-spacing: 0;
  font-family: Grandstander, Helvetica;
  font-size: 13px;
  font-weight: 400;
  /* width: 280px; */
}

.MARV-m .group-wrapper {
  width: 136px;
  height: 41px;
  position: absolute;
  top: 1503px;
  left: 127px;
}

.MARV-m .group-2 {
  height: 41px;
  width: 138px;
  cursor: pointer;
}

.MARV-m .overlap-group-3 {
  background: linear-gradient(
    180deg,
    rgb(153.27, 93.76, 229) 0%,
    rgb(85, 52, 127) 100%
  );
  border-radius: 10px;
  height: 41px;
  position: relative;
  width: 136px;
}

.MARV-m .text-wrapper-8 {
  color: #ffffff;
  font-family: "Gin-Regular", Helvetica;
  font-size: 27px;
  font-weight: 400;
  height: 32px;
  left: 12px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  text-align: center;
  top: 4px;
  white-space: nowrap;
  width: 111px;
}

.MARV-m .marv-story {
  width: 398px;
  height: 1125px;
  position: absolute;
  top: 2225px;
  left: 26px;
}

.MARV-m .text-wrapper-9 {
  -webkit-text-stroke: 1px #056825;
  color: #fff;
  letter-spacing: 0;
  text-align: center;
  width: 338px;
  height: 111px;
  font-family: Gin-Regular, Helvetica;
  font-size: 45px;
  font-weight: 400;
  line-height: normal;
  position: absolute;
  top: 6px;
  left: 0px;
}

.MARV-m .total-billion {
  color: #fff;
  letter-spacing: 0;
  text-align: center;
  width: 320px;
  font-family: Grandstander, Helvetica;
  font-size: 16px;
  font-weight: 400;
  line-height: normal;
  position: absolute;
  top: 65px;
  left: 10px;
}

.MARV-m .b {
  width: 389px;
  height: 130px;
  position: absolute;
  top: 386px;
  left: 0px;
}

.MARV-m .overlap-group-4 {
  width: 338px;
  height: 130px;
  position: relative;
  top: 269px;
}

.MARV-m .rectangle-3 {
  background-color: #72b26e;
  border-radius: 40px;
  width: 130px;
  height: 338px;
  position: absolute;
  top: -117px;
  left: 107px;
  transform: rotate(90deg);
}

.MARV-m .text-wrapper-10 {
  color: #fff;
  letter-spacing: 0;
  text-align: center;
  width: 338px;
  height: 46px;
  font-family: Gin-Regular, Helvetica;
  font-size: 32px;
  font-weight: 400;
  line-height: normal;
  position: absolute;
  top: 9px;
  left: 0px;
}

.MARV-m .text-wrapper-11 {
  color: #fff;
  letter-spacing: 0;
  text-align: center;
  font-family: Grandstander, Helvetica;
  font-size: 14px;
  font-weight: 400;
  line-height: normal;
  position: absolute;
  top: 57px;
  left: 11px;
}

.MARV-m .overlap-wrapper {
  width: 338px;
  height: 130px;
  position: absolute;
  top: 563px;
  left: 0px;
}

.MARV-m .b-2 {
  width: 338px;
  height: 130px;
  position: absolute;
  top: 740px;
  left: 0px;
}

.MARV-m .b-3 {
  width: 338px;
  height: 130px;
  position: absolute;
  top: 917px;
  left: 0px;
}

.MARV-m .contact {
  width: 169px;
  height: 33px;
  position: absolute;
  top: 1340px;
  left: 106px;
}

.MARV-m .twitter-b-2 {
  background-color: #72b26e;
  border: 1px solid;
  border-color: #ffffff;
  border-radius: 10px;
  height: 33px;
  left: 0;
  position: absolute;
  top: 0;
  width: 36px;
  cursor: pointer;
}

.MARV-m .rectangle-wrapper {
  background-image: url(../../../static/img/rectangle-2960.png);
  background-size: 100% 100%;
  height: 17px;
  left: 9px;
  position: relative;
  top: 8px;
  width: 17px;
}

.MARV-m .medium-b-2 {
  cursor: pointer;
  background-color: #72b26e;
  border: 1px solid #fff;
  border-radius: 10px;
  width: 36px;
  height: 33px;
  position: absolute;
  top: 0px;
  left: -41px;
}

.MARV-m .rectangle-4 {
  height: 17px;
  left: 0;
  position: absolute;
  top: 0;
  width: 17px;
}

.MARV-m .buy-b-2 {
  height: 33px;
  left: 58px;
  position: absolute;
  top: 0;
  width: 113px;
  cursor: pointer;
}

.MARV-m .overlap-3 {
  background: linear-gradient(
    180deg,
    rgb(153.27, 93.76, 229) 0%,
    rgb(85, 52, 127) 100%
  );
  border: 1px solid;
  border-color: #ffffff;
  border-radius: 10px;
  height: 33px;
  position: relative;
  width: 111px;
}

.MARV-m .text-wrapper-12 {
  color: #fff;
  letter-spacing: 0;
  text-align: center;
  width: 111px;
  height: 32px;
  font-family: Gin-Regular, Helvetica;
  font-size: 20px;
  font-weight: 400;
  line-height: normal;
  position: absolute;
  top: 2px;
  left: -1px;
}

.MARV-m .find-us {
  width: 338px;
  height: 247px;
  position: absolute;
  top: 4160px;
  left: 27px;
}

.MARV-m .dextools {
  width: 89px;
  height: 101px;
  position: absolute;
  top: 146px;
  left: 58px;
}

.MARV-m .vector-2 {
  height: 61px;
  position: absolute;
  top: 0px;
  left: 0px;
}

.MARV-m .vector-wrapper {
  background: linear-gradient(#995ee5 0%, #55347f 100%);
  border-radius: 67.61px;
  width: 60px;
  height: 60px;
  position: relative;
  top: -69px;
  left: 22px;
}

.MARV-m .coinranking {
  position: absolute;
  top: 146px;
  left: 172px;
}

.MARV-m .vector {
  height: 50px;
  position: absolute;
  top: 4px;
  left: 8px;
}

.MARV-m .text-wrapper-13 {
  -webkit-text-stroke: 1px #056825;
  color: #fff;
  letter-spacing: 0;
  text-align: center;
  width: 338px;
  height: 111px;
  font-family: Gin-Regular, Helvetica;
  font-size: 58px;
  font-weight: 400;
  line-height: normal;
  position: absolute;
  top: -1px;
  left: -1px;
}

.MARV-m .join-the-adventure {
  width: 338px;
  height: 686px;
  position: absolute;
  top: 3430px;
  left: 29px;
}

.MARV-m .purple-block {
  width: 338px;
  height: 323px;
  position: absolute;
  top: 174px;
  left: 0px;
}

.MARV-m .overlap-group-5 {
  width: 338px;
  height: 323px;
  position: relative;
}

.MARV-m .rectangle-5 {
  background-color: #a97ce3;
  border-radius: 40px;
  width: 323px;
  height: 341px;
  position: absolute;
  top: 46px;
  left: 8px;
  /* transform: rotate(90deg); */
}

.MARV-m .text-wrapper-13s {
  -webkit-text-stroke: 1px #056825;
  color: #fff;
  letter-spacing: 0;
  text-align: center;
  width: 338px;
  height: 111px;
  font-family: Gin-Regular, Helvetica;
  font-size: 48px;
  font-weight: 400;
  line-height: normal;
  position: absolute;
  top: 152px;
  left: -1px;
}

.MARV-m .text-wrapper-14 {
  color: #fff;
  letter-spacing: 0;
  z-index: 9;
  width: 38px;
  height: 46px;
  font-family: Gin-Regular, Helvetica;
  font-size: 32px;
  font-weight: 400;
  line-height: normal;
  position: absolute;
  top: 98px;
  left: 25px;
}

.MARV-m .text-wrapper-15 {
  color: #55347f;
  letter-spacing: 0;
  width: 386px;
  height: 46px;
  font-family: Gin-Regular, Helvetica;
  font-size: 27px;
  font-weight: 400;
  line-height: normal;
  position: absolute;
  top: 342px;
  left: 27px;
  z-index: 9;
}

.MARV-m .of-course-i-find-a {
  color: #fff;
  letter-spacing: 0;
  width: 291px;
  font-family: Grandstander, Helvetica;
  font-size: 14px;
  font-weight: 400;
  line-height: normal;
  position: absolute;
  top: 261px;
  left: 33px;
  z-index: 9;
}

.MARV-m .freepik-export {
  mix-blend-mode: luminosity;
  object-fit: cover;
  height: 210px;
  position: absolute;
  top: 43px;
  left: 31px;
}

.MARV-m .nft {
  cursor: pointer;
  width: 198px;
  height: 82px;
  position: absolute;
  top: 1321px;
  left: 12px;
}

.MARV-m .NFT-coming-soon-wrapper {
  background: linear-gradient(rgb(153, 94, 229) 0%, rgb(85, 52, 127) 100%);
  border-radius: 10px;
  width: 311px;
  height: 33px;
  position: relative;
}

.MARV-m .NFT-coming-soon {
  color: rgb(255, 255, 255);
  letter-spacing: 0px;
  text-align: center;
  width: 304px;
  height: 50px;
  font-family: Gin-Regular, Helvetica;
  font-size: 19px;
  font-weight: 400;
  line-height: 33.4px;
  position: absolute;
  top: -1px;
  left: 3px;
}

.MARV-m .text-wrapper-16 {
  color: rgb(255, 255, 255);
  letter-spacing: 0px;
  font-family: Gin-Regular, Helvetica;
  font-size: 19px;
  font-weight: 400;
  line-height: 33.4px;
}

.MARV-m .text-wrapper-17 {
  font-size: 14px;
  line-height: 27.2px;
  position: absolute;
  width: 203px;
  left: 46px;
}

.MARV-m .group-3 {
  width: 398px;
  height: 96px;
  position: absolute;
  top: 590px;
  left: -30px;
}

.MARV-m .overlap-4 {
  height: 96px;
  position: relative;
}

.MARV-m .rectangle-6 {
  height: 85px;
  left: 0;
  position: absolute;
  top: 11px;
  width: 1437px;
}

.MARV-m .frame {
  height: 170px; /* Adjusted for two lines of text */
  overflow: hidden;
  position: absolute;
  top: 13px;
  width: 100%;
  display: flex;
  white-space: nowrap;
}

.MARV-m .text-wrapper-18 {
  color: #55347f;
  font-family: "Gin-Regular", Helvetica;
  font-size: 32px;
  line-height: 85px; /* Ensures vertical alignment within each line */
  animation: scrollText 20s linear infinite;
}

/* Keyframes for the scrolling text animation */
@keyframes scrollText {
  0% {
    transform: translateX(0%); /* Start with text fully visible */
  }
  100% {
    transform: translateX(-50%); /* Move to left half the total width */
  }
}

.MARV-m .footer {
  background-color: #0000;
  width: 296px;
  height: 240px;
  position: absolute;
  top: 4480px;
  left: 25px;
}

.MARV-m .medium-3 {
  width: 22px;
  height: 15px;
  position: absolute;
  top: 9px;
  left: 5px;
}

.MARV-m .telegram-wrapper {
  background-color: #72b26e;
  border-radius: 10px;
  width: 36px;
  height: 33px;
  position: absolute;
  top: 206px;
  left: 185px;
}

.MARV-m .twitter-wrapper {
  background-color: #72b26e;
  border-radius: 10px;
  width: 36px;
  height: 33px;
  position: absolute;
  top: 206px;
  left: 98px;
}

.MARV-m .buy-b-3 {
  width: 111px;
  height: 33px;
  position: absolute;
  top: 206px;
  left: 238px;
}

.MARV-m .LOGO-2 {
  object-fit: cover;
  height: 56%;
  position: absolute;
  top: 47px;
  left: 52px;
}

.MARV-m .story-b-2 {
  width: 71px;
  height: 33px;
  position: absolute;
  top: 206px;
  left: 14px;
}

.MARV-m .box {
  width: 1140px;
  height: 156px;
  position: absolute;
  top: 428px;
  margin-top: 20px;
}

.MARV-m .new-basket {
  width: 338px;
  height: 156px;
  position: absolute;
  top: 0;
  left: 0;
}

.MARV-m .box-overlap {
  width: 338px;
  height: 156px;
  position: relative;
}

.MARV-m .box-overlap-group {
  width: 338px;
  height: 156px;
  position: absolute;
  top: 0;
  left: 0;
}

.MARV-m .box-rectangle {
  border: 4px solid #aa7ce4;
  border-radius: 40px;
  width: 156px;
  height: 300px;
  position: absolute;
  top: -52px;
  left: 93px;
  transform: rotate(90deg);
  box-shadow: 0 8px 8px #00000040;
}

.MARV-m .box-text-wrapper {
  color: #aa7ce4;
  letter-spacing: 0;
  width: 321px;
  height: 46px;
  font-family: Gin-Regular, Helvetica;
  font-size: 23px;
  font-weight: 400;
  line-height: normal;
  position: absolute;
  top: 29px;
  left: 50px;
}

.MARV-m .div110 {
  color: #fff;
  letter-spacing: 0;
  width: 307px;
  font-family: Grandstander-Regular, Helvetica;
  font-size: 14px;
  font-weight: 400;
  line-height: normal;
  position: absolute;
  top: 110px;
  left: 36px;
}

.MARV-m .box-text-wrapper-2 {
  color: #fff;
  letter-spacing: 0;
  text-align: center;
  white-space: nowrap;
  width: 156px;
  font-family: Grandstander-Regular, Helvetica;
  font-size: 14px;
  font-weight: 400;
  line-height: normal;
  position: absolute;
  top: 142px;
  left: 158px;
}

.MARV-m .box-overlap-2 {
  height: 80px;
  left: 38px;
  position: absolute;
  top: 35px;
  width: 262px;
}

.MARV-m .box-element {
  object-fit: contain;
  width: 50%;
  height: auto;
  margin: 0 auto;
  position: relative;
  top: 34px;
  left: -9px;
}

.MARV-m .android-chrome {
  height: 80px;
  left: 46px;
  object-fit: cover;
  position: absolute;
  top: 0;
  width: 80px;
}

.MARV-m .box-img {
  height: 80px;
  left: 91px;
  object-fit: cover;
  position: absolute;
  top: 0;
  width: 80px;
}

.MARV-m .mog {
  height: 80px;
  left: 137px;
  object-fit: cover;
  position: absolute;
  top: 0;
  width: 80px;
}

.MARV-m .box-element-2 {
  height: 80px;
  left: 182px;
  object-fit: cover;
  position: absolute;
  top: 0;
  width: 80px;
}

.MARV-m .box-buy-b {
  opacity: 0.2;
  width: 158px;
  height: 47px;
  position: absolute;
  top: 68px;
  left: 171px;
}

.MARV-m .box-div-wrapper {
  background: linear-gradient(#995ee5 0%, #55347f 100%);
  border: 1px solid #fff;
  border-radius: 10px;
  width: 136px;
  height: 37px;
  position: relative;
}

.MARV-m .box-text-wrapper-3 {
  color: #fff;
  letter-spacing: 0;
  text-align: center;
  width: 156px;
  height: 45px;
  font-family: Gin-Regular, Helvetica;
  font-size: 20px;
  font-weight: 400;
  line-height: normal;
  position: absolute;
  top: 5px;
  left: -8px;
}

.MARV-m .cex-1 {
  height: 34px;
  top: 219px;
  position: relative;
  padding: 2px;
  left: 24px;
}

.MARV-m .cex-2 {
  height: 34px;
  top: 219px;
  position: relative;
  padding: 2px;
  left: 24px;
}

.MARV-m .cex-3 {
  height: 34px;
  top: 219px;
  position: relative;
  padding: 2px;
  left: 24px;
}

.MARV-m .cex-4 {
  height: 34px;
  top: 219px;
  position: relative;
  padding: 2px;
  left: 24px;
}

.MARV-m .cex-5 {
  height: 34px;
  top: 219px;
  position: relative;
  padding: 2px;
  left: 24px;
}

.MARV-m .cex-6 {
  height: 34px;
  top: 219px;
  position: relative;
  padding: 2px;
  left: 24px;
}

.MARV-m .purplebubble {
  background-color: #995ee5;
  border-radius: 55px;
  width: 350px;
  height: 100px;
  position: relative;
  top: 394px;
  left: 15px;
}

.MARV-m .marvtoken {
  width: 100px;
  height: 100px;
  position: relative;
  left: 0px;
}

.MARV-m .price {
  letter-spacing: 0;
  text-align: center;
  color: #fff;
  font-family: Grandstander, Helvetica;
  font-size: 15px;
  font-weight: 400;
  line-height: normal;
  position: relative;
  bottom: 74px;
  left: 42px;
}

.MARV-m .marvprice {
  font-size: 10px;
  font-family: "Gin-Regular", Helvetica;
  color: white;
}

.MARV-m .marvliquidity {
  font-size: 10px;
  font-family: "Gin-Regular", Helvetica;
  color: white;
}

.MARV-m .liquidity {
  letter-spacing: 0;
  text-align: center;
  color: #fff;
  font-family: Grandstander, Helvetica;
  font-size: 15px;
  font-weight: 400;
  line-height: normal;
  position: relative;
  bottom: 57px;
  left: -1px;
}
