html {
  scroll-behavior: smooth;
}

/* Define Gin-Regular */
@font-face {
  font-family: "Gin-Regular";
  src: url("/src/fonts/Gin-Regular.otf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

/* Define Grandstander */
@font-face {
  font-family: "Grandstander";
  src: url("/src/fonts/Grandstander-VariableFont_wght.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

.MARV {
  background-color: transparent;
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
}

.MARV .div {
  background-image: url(../../../static/img/DBG.png);
  background-size: cover;
  border-radius: 40px;
  width: 1437.05px;
  height: 4544px;
  min-height: 100vh;
  margin: 25px;
  padding: 5px;
  position: relative;
  overflow: hidden;
}

.MARV .NAV {
  height: 58px;
  left: 242px;
  position: absolute;
  top: 28px;
  width: 954px;
}

.MARV .LOGO {
  height: 58px;
  left: 0;
  object-fit: cover;
  position: absolute;
  top: 0;
  width: 100px;
  cursor: pointer;
}

.MARV .story-b {
  width: 71px;
  height: 33px;
  position: absolute;
  top: 18px;
  left: 625px;
}

.MARV .group {
  height: 33px;
  width: 73px;
}

.MARV .overlap-group {
  border: 0.5px solid;
  border-color: #ffffff;
  border-radius: 10px;
  height: 33px;
  position: relative;
  width: 71px;
}

.MARV .text-wrapper {
  color: #fff;
  letter-spacing: 0;
  text-align: center;
  width: 71px;
  height: 32px;
  font-family: Gin-Regular, Helvetica;
  font-size: 19px;
  font-weight: 400;
  line-height: normal;
  position: absolute;
  top: 4px;
  left: 0;
}

.MARV .telegram-b-2 {
  background-color: #72b26e;
  border: 1px solid #fff;
  border-radius: 10px;
  width: 36px;
  height: 33px;
  position: absolute;
  left: -86px;
}

.MARV .medium-b {
  background-color: #72b26e;
  border-radius: 10px;
  width: 36px;
  height: 33px;
  position: absolute;
  top: 18px;
  left: 705px;
}

.MARV .medium {
  width: 22px;
  height: 15px;
  position: absolute;
  top: 9px;
  left: 7px;
}

.MARV .medium-3 {
  background-color: #72b26e;
  border-radius: 10px;
  width: 36px;
  height: 33px;
  position: absolute;
  top: 206px;
  left: 70px;
}

.MARV .medium-b-2 {
  background-color: #72b26e;
  border: 1px solid #fff;
  border-radius: 10px;
  width: 36px;
  height: 33px;
  position: absolute;
  top: 0;
  left: -43px;
}

.MARV .tellagram-b {
  background-color: #72b26e;
  border-radius: 10px;
  height: 33px;
  left: 745px;
  position: absolute;
  top: 18px;
  width: 36px;
}

.MARV .telegram {
  height: 15px;
  left: 7px;
  position: absolute;
  top: 10px;
  width: 18px;
}

.MARV .medium-2 {
  width: 18px;
  height: 15px;
  position: absolute;
  top: 9px;
  left: 7px;
}

.MARV .twitter-b {
  background-color: #72b26e;
  border-radius: 10px;
  height: 33px;
  left: 785px;
  position: absolute;
  top: 18px;
  width: 36px;
}

.MARV .twitter {
  height: 17px;
  left: 9px;
  position: absolute;
  top: 8px;
  width: 17px;
}

.MARV .buy-b {
  height: 33px;
  left: 843px;
  position: absolute;
  top: 18px;
  width: 111px;
}

.MARV .medium-3 {
  width: 22px;
  height: 15px;
  position: absolute;
  top: 9px;
  left: 7px;
}

.MARV .overlap-group-wrapper {
  height: 33px;
  width: 113px;
}

.MARV .div-wrapper {
  cursor: pointer;
  background: linear-gradient(#995ee5 0%, #55347f 100%);
  border-radius: 10px;
  width: 114px;
  height: 33px;
  position: relative;
  transition: transform 0.3s ease; /* Smooth transition for transform */
}

.MARV .div-wrapper:hover {
  transform: scale(1.1); /* Increase size by 10% */
}

.MARV .medium-b-3 {
  background-color: #72b26e;
  /* border: 1px solid #fff; */
  border-radius: 10px;
  width: 36px;
  height: 33px;
  position: absolute;
  top: 206px;
  left: 70px;
}

.MARV .text-wrapper-2 {
  color: #fff;
  letter-spacing: 0;
  text-align: center;
  width: 121px;
  height: 32px;
  font-family: Gin-Regular, Helvetica;
  font-size: 19px;
  font-weight: 400;
  line-height: normal;
  position: absolute;
  top: 4px;
  left: -1px;
}

.MARV .landing-text {
  height: 424px;
  left: 242px;
  position: absolute;
  top: 332px;
  width: 960px;
}

.MARV .text-wrapper-3 {
  -webkit-text-stroke: 1px #056825;
  color: #fff;
  letter-spacing: 0;
  text-align: center;
  text-shadow: 0 4px 15px #00000040;
  white-space: nowrap;
  height: 111px;
  font-family: Gin-Regular, Helvetica;
  font-size: 107px;
  font-weight: 400;
  line-height: normal;
  position: absolute;
  top: -19px;
  left: 91px;
}

.MARV .derived-from-love {
  color: #fff;
  letter-spacing: 0;
  text-align: center;
  width: 954px;
  font-family: Grandstander, Helvetica;
  font-size: 29px;
  font-weight: 400;
  line-height: normal;
  position: absolute;
  top: 121px;
  left: 0;
}

.MARV .text-wrapper-4 {
  -webkit-text-stroke: 1px #378b32;
  color: #ffffff;
  font-family: "Gin-Regular", Helvetica;
  font-size: 44px;
  font-weight: 400;
  height: 88px;
  left: -1px;
  letter-spacing: -0.88px;
  line-height: normal;
  position: absolute;
  text-align: center;
  top: 335px;
  width: 170px;
}

.MARV .ticket-tape {
  height: 170px; /* Adjusted for two lines of text */
  overflow: hidden;
  position: absolute;
  top: 767px;
  width: 100%;
  display: flex;
  white-space: nowrap;
}

.MARV .p {
  color: #55347f;
  font-family: "Gin-Regular", Helvetica;
  font-size: 32px;
  line-height: 85px; /* Ensures vertical alignment within each line */
  animation: scrollText 20s linear infinite;
}

/* Keyframes for the scrolling text animation */
@keyframes scrollText {
  0% {
    transform: translateX(0%); /* Start with text fully visible */
  }
  100% {
    transform: translateX(-50%); /* Move to left half the total width */
  }
}

.MARV .token {
  height: 740px;
  left: 146px;
  position: absolute;
  top: 942px;
  width: 1149px;
}

.MARV .text-wrapper-5 {
  -webkit-text-stroke: 1px #056825;
  color: #fff;
  letter-spacing: 0;
  text-align: center;
  width: 759px;
  height: 111px;
  font-family: Gin-Regular, Helvetica;
  font-size: 58px;
  font-weight: 400;
  line-height: normal;
  position: absolute;
  top: -13px;
  left: 192px;
}

.MARV .text-wrapper-6 {
  color: #fff;
  letter-spacing: 0;
  text-align: center;
  width: 954px;
  font-family: Grandstander, Helvetica;
  font-size: 20px;
  font-weight: 400;
  line-height: normal;
  position: absolute;
  top: 80px;
  left: 96px;
}

.MARV .boxes {
  height: 409px;
  left: 0;
  position: absolute;
  top: 179px;
  width: 1149px;
}

.MARV .overlap {
  height: 409px;
  left: 389px;
  position: absolute;
  top: 0;
  width: 365px;
}

.MARV .rectangle {
  background-color: #d9d9d9;
  border-radius: 40px;
  height: 409px;
  left: 1px;
  position: absolute;
  top: 0;
  width: 364px;
}

.MARV .img {
  height: 284px;
  left: 38px;
  position: absolute;
  top: 27px;
  width: 291px;
}

.MARV .text-wrapper-7 {
  color: #72b26e;
  letter-spacing: 0;
  text-align: center;
  width: 364px;
  height: 98px;
  font-family: Gin-Regular, Helvetica;
  font-size: 37px;
  font-weight: 400;
  line-height: normal;
  position: absolute;
  top: 328px;
  left: 0;
}

.MARV .overlap-group-2 {
  background-color: #d9d9d9;
  border-radius: 40px;
  height: 409px;
  left: 0;
  position: absolute;
  top: 0;
  width: 364px;
}

.MARV .rectangle-2 {
  height: 284px;
  left: 36px;
  position: absolute;
  top: 27px;
  width: 291px;
}

.MARV .overlap-2 {
  background-color: #d9d9d9;
  border-radius: 40px;
  height: 409px;
  left: 778px;
  position: absolute;
  top: 0;
  width: 364px;
}

.MARV .CA {
  color: #ffffff;
  font-family: "Grandstander", Helvetica;
  font-size: 26px;
  font-weight: 400;
  left: 96px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  text-align: center;
  top: 637px;
  width: 954px;
}

.MARV .span {
  color: #ffffff;
  font-family: "Grandstander", Helvetica;
  font-size: 26px;
  font-weight: 400;
  letter-spacing: 0;
}

.MARV .group-wrapper {
  height: 41px;
  left: 504px;
  position: absolute;
  top: 699px;
  width: 136px;
}

.MARV .group-2 {
  height: 41px;
  width: 138px;
  cursor: pointer;
}

.MARV .overlap-group-3 {
  background: linear-gradient(
    180deg,
    rgb(153.27, 93.76, 229) 0%,
    rgb(85, 52, 127) 100%
  );
  border-radius: 10px;
  height: 41px;
  position: relative;
  width: 136px;
}

.MARV .text-wrapper-8 {
  color: #ffffff;
  font-family: "Gin-Regular", Helvetica;
  font-size: 27px;
  font-weight: 400;
  height: 32px;
  left: 12px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  text-align: center;
  top: 4px;
  white-space: nowrap;
  width: 111px;
}

.MARV .marv-story {
  height: 1125px;
  left: 145px;
  position: absolute;
  top: 1779px;
  width: 1151px;
}

.MARV .text-wrapper-9 {
  -webkit-text-stroke: 1px #056825;
  color: #fff;
  letter-spacing: 0;
  text-align: center;
  width: 759px;
  height: 111px;
  font-family: Gin-Regular, Helvetica;
  font-size: 58px;
  font-weight: 400;
  line-height: normal;
  position: absolute;
  top: -22px;
  left: 193px;
}

.MARV .total-billion {
  color: #fff;
  letter-spacing: 0;
  text-align: center;
  width: 1147px;
  font-family: Grandstander, Helvetica;
  font-size: 20px;
  font-weight: 400;
  line-height: normal;
  position: absolute;
  top: 65px;
  left: 0;
}

.MARV .b {
  height: 130px;
  left: 185px;
  position: absolute;
  top: 386px;
  width: 779px;
}

.MARV .overlap-group-4 {
  width: 775px;
  height: 130px;
  position: relative;
  top: 200px;
}

.MARV .rectangle-3 {
  background-color: #72b26e;
  border-radius: 40px;
  height: 775px;
  left: 323px;
  position: absolute;
  top: -323px;
  transform: rotate(90deg);
  width: 130px;
}

.MARV .text-wrapper-10 {
  color: #ffffff;
  font-family: "Gin-Regular", Helvetica;
  font-size: 32px;
  font-weight: 400;
  height: 46px;
  left: 195px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  text-align: center;
  top: 9px;
  width: 386px;
}

.MARV .text-wrapper-11 {
  color: #ffffff;
  font-family: "Grandstander", Helvetica;
  font-size: 20px;
  font-weight: 400;
  left: 35px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  text-align: center;
  top: 67px;
  width: 706px;
}

.MARV .overlap-wrapper {
  height: 130px;
  left: 185px;
  position: absolute;
  top: 563px;
  width: 779px;
}

.MARV .b-2 {
  height: 130px;
  left: 185px;
  position: absolute;
  top: 740px;
  width: 779px;
}

.MARV .b-3 {
  height: 130px;
  left: 185px;
  position: absolute;
  top: 917px;
  width: 779px;
}

.MARV .contact {
  width: 232px;
  height: 33px;
  position: absolute;
  top: 1304px;
  left: 538px;
}

.MARV .twitter-b-2 {
  background-color: #72b26e;
  border: 1px solid;
  border-color: #ffffff;
  border-radius: 10px;
  height: 33px;
  left: 0;
  position: absolute;
  top: 0;
  width: 36px;
}

.MARV .rectangle-wrapper {
  background-image: url(../../../static/img/rectangle-2960.png);
  background-size: 100% 100%;
  height: 17px;
  left: 9px;
  position: relative;
  top: 8px;
  width: 17px;
}

.MARV .rectangle-4 {
  height: 17px;
  left: 0;
  position: absolute;
  top: 0;
  width: 17px;
}

.MARV .buy-b-2 {
  height: 33px;
  left: 58px;
  position: absolute;
  top: 0;
  width: 113px;
  cursor: pointer;
}

.MARV .overlap-3 {
  background: linear-gradient(
    180deg,
    rgb(153.27, 93.76, 229) 0%,
    rgb(85, 52, 127) 100%
  );
  border: 1px solid;
  border-color: #ffffff;
  border-radius: 10px;
  height: 33px;
  position: relative;
  width: 111px;
}

.MARV .text-wrapper-12 {
  color: #fff;
  letter-spacing: 0;
  text-align: center;
  width: 111px;
  height: 32px;
  font-family: Gin-Regular, Helvetica;
  font-size: 20px;
  font-weight: 400;
  line-height: normal;
  position: absolute;
  top: 2px;
  left: -1px;
}

.MARV .find-us {
  width: 761px;
  height: 247px;
  position: absolute;
  top: 3191px;
  left: 339px;
}

.MARV .dextools {
  width: 89px;
  height: 101px;
  position: absolute;
  top: 146px;
  left: 242px;
}

.MARV .vector-2 {
  height: 101px;
  position: absolute;
  top: 0;
  left: 0;
}

.MARV .vector-wrapper {
  background: linear-gradient(#995ee5 0%, #55347f 100%);
  border-radius: 67.61px;
  width: 100px;
  height: 100px;
  position: relative;
  top: -60px;
  left: -8px;
}

.MARV .coinranking {
  position: absolute;
  top: 146px;
  left: 415px;
}

.MARV .vector {
  height: 76px;
  position: absolute;
  top: 11px;
  left: 17px;
}

.MARV .text-wrapper-13 {
  -webkit-text-stroke: 1px #056825;
  color: #ffffff;
  font-family: "Gin-Regular", Helvetica;
  font-size: 58px;
  font-weight: 400;
  height: 111px;
  left: -1px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  text-align: center;
  top: -1px;
  width: 759px;
}

.MARV .join-the-adventure {
  height: 686px;
  left: 0;
  position: absolute;
  top: 3375px;
  width: 1437px;
}

.MARV .purple-block {
  width: 1008px;
  height: 323px;
  position: absolute;
  top: 174px;
  left: 235px;
}

.MARV .overlap-group-5 {
  height: 323px;
  position: relative;
  width: 1002px;
}

.MARV .rectangle-5 {
  background-color: #a97ce3;
  border-radius: 40px;
  height: 963px;
  left: 320px;
  position: absolute;
  top: -320px;
  transform: rotate(90deg);
  width: 323px;
}

.MARV .text-wrapper-14 {
  color: #ffffff;
  font-family: "Gin-Regular", Helvetica;
  font-size: 32px;
  font-weight: 400;
  height: 46px;
  left: 59px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  top: 51px;
  width: 386px;
}

.MARV .text-wrapper-15 {
  color: #55347f;
  font-family: "Gin-Regular", Helvetica;
  font-size: 32px;
  font-weight: 400;
  height: 46px;
  left: 59px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  top: 239px;
  width: 386px;
}

.MARV .of-course-i-find-a {
  color: #ffffff;
  font-family: "Grandstander", Helvetica;
  font-size: 20px;
  font-weight: 400;
  left: 59px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  top: 109px;
  width: 469px;
}

.MARV .freepik-export {
  height: 318px;
  left: 447px;
  mix-blend-mode: luminosity;
  object-fit: cover;
  position: absolute;
  top: 5px;
  width: 555px;
}

.MARV .nft {
  cursor: pointer;
  width: 198px;
  height: 82px;
  position: absolute;
  top: 1048px;
  left: 564px;
}

.MARV .NFT-coming-soon-wrapper {
  background: linear-gradient(rgb(153, 94, 229) 0%, rgb(85, 52, 127) 100%);
  border-radius: 10px;
  width: 311px;
  height: 33px;
  position: relative;
}

.MARV .NFT-coming-soon {
  color: rgb(255, 255, 255);
  letter-spacing: 0px;
  text-align: center;
  width: 304px;
  height: 50px;
  font-family: Gin-Regular, Helvetica;
  font-size: 19px;
  font-weight: 400;
  line-height: 33.4px;
  position: absolute;
  top: -1px;
  left: 3px;
}

.MARV .text-wrapper-16 {
  color: rgb(255, 255, 255);
  letter-spacing: 0px;
  font-family: Gin-Regular, Helvetica;
  font-size: 19px;
  font-weight: 400;
  line-height: 33.4px;
}

.MARV .text-wrapper-17 {
  font-size: 14px;
  line-height: 27.2px;
  position: absolute;
  width: 203px;
  left: 46px;
}

.MARV .group-3 {
  height: 96px;
  left: 0;
  position: absolute;
  top: 590px;
  width: 1437px;
}

.MARV .overlap-4 {
  height: 96px;
  position: relative;
}

.MARV .rectangle-6 {
  height: 85px;
  left: 0;
  position: absolute;
  top: 11px;
  width: 1437px;
}

.MARV .frame {
  height: 170px; /* Adjusted for two lines of text */
  overflow: hidden;
  position: absolute;
  top: 13px;
  width: 100%;
  display: flex;
  white-space: nowrap;
}

.MARV .text-wrapper-18 {
  color: #55347f;
  font-family: "Gin-Regular", Helvetica;
  font-size: 32px;
  line-height: 85px; /* Ensures vertical alignment within each line */
  animation: scrollText 20s linear infinite;
}

/* Keyframes for the scrolling text animation */
@keyframes scrollText {
  0% {
    transform: translateX(0%); /* Start with text fully visible */
  }
  100% {
    transform: translateX(-50%); /* Move to left half the total width */
  }
}

.MARV .footer {
  background-color: transparent;
  height: 240px;
  left: 565px;
  position: absolute;
  top: 4170px;
  width: 296px;
}

.MARV .telegram-wrapper {
  background-color: #72b26e;
  border-radius: 10px;
  width: 36px;
  height: 33px;
  position: absolute;
  top: 206px;
  left: 108px;
}

.MARV .twitter-wrapper {
  background-color: #72b26e;
  border-radius: 10px;
  width: 36px;
  height: 33px;
  position: absolute;
  top: 206px;
  left: 146px;
}

.MARV .buy-b-3 {
  width: 111px;
  height: 33px;
  position: absolute;
  top: 206px;
  left: 195px;
}

.MARV .LOGO-2 {
  object-fit: cover;
  width: 295px;
  height: 170px;
  position: absolute;
  top: 0px;
  left: 11px;
}

.MARV .story-b-2 {
  width: 71px;
  height: 33px;
  position: absolute;
  top: 206px;
  left: -4px;
}

.box {
  width: 1140px;
  height: 156px;
  top: 330px;
  position: absolute;
}

.box .new-basket {
  width: 1146px;
  height: 156px;
  position: absolute;
  top: 0px;
  left: 0;
}

.box .box-overlap {
  height: 156px;
  position: relative;
  width: 1140px;
}

.box .box-overlap-group {
  height: 156px;
  left: 0;
  position: absolute;
  top: 0;
  width: 1140px;
}

.box .box-rectangle {
  border: 4px solid;
  border-color: #aa7ce4;
  border-radius: 40px;
  box-shadow: 0px 8px 8px #00000040;
  height: 1140px;
  left: 492px;
  position: absolute;
  top: -492px;
  transform: rotate(90deg);
  width: 156px;
}

.box .box-text-wrapper {
  color: #aa7ce4;
  letter-spacing: 0;
  width: 405px;
  height: 46px;
  font-family: Gin-Regular, Helvetica;
  font-size: 38px;
  font-weight: 400;
  line-height: normal;
  position: absolute;
  top: 29px;
  left: 328px;
}

.box .div110 {
  color: #ffffff;
  font-family: "Grandstander-Regular", Helvetica;
  font-size: 19px;
  font-weight: 400;
  left: 328px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  top: 86px;
  width: 571px;
}

.box .box-text-wrapper-2 {
  color: #ffffff;
  font-family: "Grandstander-Regular", Helvetica;
  font-size: 20px;
  font-weight: 400;
  left: 938px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  text-align: center;
  top: 108px;
  white-space: nowrap;
  width: 156px;
}

.box .box-overlap-2 {
  height: 80px;
  left: 38px;
  position: absolute;
  top: 35px;
  width: 262px;
}

.box .box-element {
  object-fit: cover;
  width: 263px;
  height: 80px;
  position: absolute;
  top: 0;
  left: -7px;
}

.box .android-chrome {
  height: 80px;
  left: 46px;
  object-fit: cover;
  position: absolute;
  top: 0;
  width: 80px;
}

.box .box-img {
  height: 80px;
  left: 91px;
  object-fit: cover;
  position: absolute;
  top: 0;
  width: 80px;
}

.box .mog {
  height: 80px;
  left: 137px;
  object-fit: cover;
  position: absolute;
  top: 0;
  width: 80px;
}

.box .box-element-2 {
  height: 80px;
  left: 182px;
  object-fit: cover;
  position: absolute;
  top: 0;
  width: 80px;
}

.box .buy-b {
  height: 47px;
  left: 938px;
  opacity: 0.2;
  position: absolute;
  top: 52px;
  width: 158px;
}

.box .box-div-wrapper {
  background: linear-gradient(
    180deg,
    rgb(153.27, 93.76, 229) 0%,
    rgb(85, 52, 127) 100%
  );
  border: 1px solid;
  border-color: #ffffff;
  border-radius: 10px;
  height: 47px;
  position: relative;
  width: 156px;
}

.box .box-text-wrapper-3 {
  color: #fff;
  letter-spacing: 0;
  text-align: center;
  width: 156px;
  height: 45px;
  font-family: Gin-Regular, Helvetica;
  font-size: 20px;
  font-weight: 400;
  line-height: normal;
  position: absolute;
  top: 8px;
  left: -1px;
}

.MARV .cex-1 {
  height: 43px;
  padding: 2px;
  position: relative;
  top: 219px;
  left: -4px;
}

.MARV .cex-2 {
  height: 43px;
  padding: 2px;
  position: relative;
  top: 219px;
  left: -4px;
}

.MARV .cex-3 {
  height: 43px;
  padding: 2px;
  position: relative;
  top: 219px;
  left: -4px;
}

.MARV .cex-4 {
  height: 43px;
  padding: 2px;
  position: relative;
  top: 219px;
  left: -4px;
}

.MARV .cex-5 {
  height: 43px;
  padding: 2px;
  position: relative;
  top: 219px;
  left: -4px;
}

.MARV .cex-6 {
  height: 43px;
  padding: 2px;
  position: relative;
  top: 219px;
  left: -4px;
}

.MARV .text-wrapper-13s {
  -webkit-text-stroke: 1px #056825;
  color: #ffffff;
  font-family: "Gin-Regular", Helvetica;
  font-size: 58px;
  font-weight: 400;
  height: 111px;
  left: -1px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  text-align: center;
  top: -1px;
  width: 759px;
}

.MARV .purplebubble {
  background-color: #995ee5;
  border-radius: 55px;
  width: 400px;
  height: 100px;
  position: relative;
  top: 550px;
  left: 500px;
}

.MARV .marvtoken {
  width: 100px;
  height: 100px;
  position: relative;
  left: 0px;
}

.MARV .price {
  color: #fff;
  font-family: Grandstander, Helvetica;
  font-size: 20px;
  position: relative;
  bottom: 81px;
  left: 110px;
}

.MARV .marvprice {
  font-size: 10px;
  font-family: "Gin-Regular", Helvetica;
  color: white;
}

.MARV .marvliquidity {
  font-size: 10px;
  font-family: "Gin-Regular", Helvetica;
  color: white;
}

.MARV .liquidity {
  color: #fff;
  font-family: Grandstander, Helvetica;
  font-size: 20px;
  position: relative;
  bottom: 63px;
  left: 110px;
}
